import React, { useEffect, useState } from 'react';
import "./AdminUpdates.css";
import AdminBackground from "../../screens/admins/images/admin-background.png"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDoc, collection } from "firebase/firestore";
import {TEAM_NAME, CLIENT_NAME} from '../../screens/admins/AdminLogin';
import { db, getTeams, getMembersNames } from '../../functions/FirebaseData';
// import { getTeams } from '../../functions/GetTeams';
import { useLocation } from 'react-router-dom';
import { createNewProject } from './AdminFunctions';


export default function AdminUpdateProejcts({ navigate, ...props }) {
    const location = useLocation();
    const org = location.state.client
    const team = "web"
    const [numClients, setNumClients] = useState(1);
    const [newClients, setNewClients] = useState([]);
    const [newProjectName, setNewProjectName] = useState("");
    const [newClient, setNewClient] = useState([]);
    




    async function addNewProject() {
      console.log("Adding new team")
        const newProject = {
            clients: newClients
        }
      await createNewProject(org, team, newProjectName.toLowerCase(), newProject)
    }
    const handleClientNameChange = (index, value) => {
        const clients = [...newClients];
        if (!clients[index]) clients[index] = { name: "", email: "" };
        clients[index].name = value;
        setNewClients(clients);
      };
  
    const handleClientEmailChange = (index, value) => {
      const clients = [...newClients];
      if (!clients[index]) clients[index] = { name: "", email: "" };
      clients[index].email = value;
      setNewClients(clients);
    };

    const addNewClient = () => {
      setNewClient([...newClients, { name: "", email: "" }]);
      setNumClients(numClients + 1);
    };

    return (
      <>
        <div className="admin-updates-access-container">
          <div className="admin-updates-access-form-container">
            <div className="admin-updates-access-welcome-message">
              Edit projects for {team}.
            </div>
            <div className="admin-updates-access-update-form">
                <div>
                    {/* Name of new Project */}
                    <input
                        className="input-input"
                        placeholder="New project name"
                        value={newProjectName}
                        onChange={(e) => setNewProjectName(e.target.value)}>
                    </input>

                    {/* Adding clients */}
                    {[...Array(numClients)].map((_, index) => (
                    <div key={index}>
                        <input
                            className="input-input"
                            placeholder={`New client name ${index + 1}`}
                            value={newClients[index]?.name || ""}
                            onChange={(e) => handleClientNameChange(index, e.target.value)}
                        ></input>
                        <input
                            className="input-input"
                            placeholder={`New client email ${index + 1}`}
                            value={newClients[index]?.email || ""}
                            onChange={(e) => handleClientEmailChange(index, e.target.value)}
                        ></input>
                    </div>
            ))}
            <button onClick={addNewClient}>Add new client</button>
            <button onClick={addNewProject} type="submit" className="button-button">
                Submit
            </button>
            </div>
                    </div>
                    </div>
                </div>
            </>
    )
  }