import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "./Teams.css";
import TeamBackground from "./images/teams-background.png"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { updateDoc, arrayUnion, doc } from "firebase/firestore";
import {TEAM_NAME, CLIENT_NAME, LOGIN_STATUS, PROJECTS_LIST} from '../../functions/Login';
import { db, getProjectsNames, getMembersNames } from '../../functions/FirebaseData';


function TeamAccess({ navigate, ...props }) {
    const [startDate, setStartDate] = useState(new Date());
    const [memberName, setMemberName] = useState(null)
    const [projectName, setProjectName] = useState(null)
    const [description, setDescription] = useState(null)
    const [submitted, setSubmitted] = useState(false)
    const [welcomeMessage, setWelcomeMessage] = useState(TEAM_NAME)
    const [projectsList, setProjectsList] = useState([
        <option key={"Select"} value="Select project">Select project</option>
    ])
    const [membersList, setMembersList] = useState([
        <option key={"Select"} value="Select member">Select member</option>
    ])



    // Pushing updates to Firebase
    async function update() {
        setSubmitted(!submitted)
        setWelcomeMessage("Submitted Successfully")
        const orgRef = doc(db, "organizations", CLIENT_NAME); // create a reference to the document
        const updateObj = {
          teamName: TEAM_NAME,
          memberName: memberName,
          projectName: projectName,
          description: description,
          date: startDate,
        };
        await updateDoc(orgRef, {
          updates: arrayUnion(updateObj) // append the new object to the updates array using arrayUnion
        });
        setSubmitted(!submitted)
      }

    // Go back
    function goBack() {
        setSubmitted(!submitted)
        setWelcomeMessage(TEAM_NAME)
    }

    useEffect(() => {
        const fetchTeamInfo = async () => {
            const projects = await getProjectsNames(CLIENT_NAME, TEAM_NAME)
            projects.forEach((name) => {
                setProjectsList((projectsList) => [...projectsList, <option key={name} value={name}>{name}</option>]);
              });

            const members = await getMembersNames(CLIENT_NAME, TEAM_NAME)
            members.forEach((member) => {
                setMembersList((membersList) => [...membersList, <option key={member} value={member}>{member}</option>]);
            });
        }
        fetchTeamInfo()
    }, [])
        
        return (
            <>
            <div className="team-access-container" style={{ backgroundSize: "cover", backgroundImage: `url(${TeamBackground})`}}>
                <div className="team-access-form-container">
                    

                {!submitted && LOGIN_STATUS &&
                    <div className="team-access-update-form">
                        <div className="team-access-welcome-message">
                        <div className="team-access-organization-name">{CLIENT_NAME}</div>
                            {welcomeMessage}
                        </div>
                        <select onChange={(e) => setMemberName(e.target.value)} className="team-access-name-input">
                            {membersList}
                        </select> 
                        <select onChange={(e) => setProjectName(e.target.value)} className="team-access-project-input">
                            {projectsList}
                        </select>  
                        <textarea placeholder="Description" className="team-access-update-description" onChange={(e) => setDescription(e.target.value)}></textarea>
                        <DatePicker className="team-access-date-picker" selected={startDate} onChange={(date) => setStartDate(date)} />
                        <input title="Submit" onClick={update} className="team-access-form-submit" type="submit"></input>
                    </div>
                    }

                {submitted && LOGIN_STATUS &&
                    <>
                    <div className="team-access-welcome-message">
                        {welcomeMessage}
                    </div>
                    <div className="team-access-update-form-post-submit">
                        <button onClick={goBack} className="team-access-form-submit-post-submit-new-update">New update</button>
                        <Link to='/home'>
                            <button className="team-access-form-submit-post-submit-home">Home</button>
                        </Link>
                    </div>
                    </>
                    }

                {!submitted && !LOGIN_STATUS &&
                    <>
                    <div className="team-access-welcome-message">
                        {welcomeMessage}
                    </div>
                    <div className="team-access-update-form-post-submit">
                        <div className="teams-access-no-login-text">Login to view this page</div>
                        <Link to='/teams-login'>
                            <button className="team-access-form-submit-post-submit-home">Login</button>
                        </Link>
                    </div>
                    </>
                    }

                </div>



            </div>
            </>
        )
    



}
export default TeamAccess;
