export function titleCase(str) {
    // split the string into an array of words
    let words = str.toLowerCase().split(' ');
    
    // loop through each word and capitalize the first letter
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    
    // join the array of words back into a string
    return words.join(' ');
  }
  