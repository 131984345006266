import { getDocs, getDoc, doc, getFirestore, collection, query } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { titleCase } from "../constants/Format.js";

    const firebaseConfig = {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: "prodash-474ff",
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID
    };

    const firebase = initializeApp(firebaseConfig);
    const db = getFirestore()


    // GET ARRAY OF DOCS
    async function getChartData(client) {
        let data = {}
        const q = query(collection(db, client));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        var docRef = doc.data();
        let team = docRef.teamName;
        let name = docRef.memberName;
        if (!data[team]) {
            data[team] = {};
        }
        if (!data[team][name]) {
            data[team][name] = 1;
        } else {
            data[team][name] += 1
        }
         });
         return data;
        }

    // GET ARRAY OF PROJECT NAMES
    async function getTeams(org) {
      const docRef = doc(db, "organizations", org);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      
      if (data && data.teams) {
        console.log("Teams List Below");
        const teamNames = Object.keys(data.teams).map((team) => titleCase(team));
        return teamNames;
      }
      
      return null;
    }

    // GET ARRAY OF PROJECT NAMES
    async function getProjectsNames(org, team) {
      const teamName = team.toLowerCase();
      const projectsList = new Set();
      const docRef = doc(db, "organizations", org);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      const projects = data.teams[teamName].projects;


      const projectsNames = Object.keys(projects).map(project => titleCase(project));
      return projectsNames; // convert Set to array and return

    }

      
      
    // GET ARRAY OF TEAM MEMBERS
    async function getMembersNames(org, team) {
      const teamName = team.toLowerCase();
      const membersList = new Set();
      const docRef = doc(db, "organizations", org);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      const members = data.teams[teamName].members;

      // use map() to create an array of member names
      const memberNames = Object.values(members).map(member => member.name);
    
      // add member names to the Set to remove duplicates
      memberNames.forEach(name => membersList.add(name));
      return [...membersList]; // convert Set to array and return
    }
      
      
      
      
      
      

    // GET ARRAY OF ORGANIZATION NAMES
    async function getOrganizationList() {
        var organizationsList = new Set()
        const querySnapshot = await getDocs(collection(db, "organizations"));
        querySnapshot.forEach((doc) => {
          organizationsList.add(doc.id);
        });
        return organizationsList
    }
    

export default firebase;
export {
    getProjectsNames,
    getMembersNames,
    getTeams,
    getOrganizationList,
    getChartData,
    db
};




