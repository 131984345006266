import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../../functions/FirebaseData";



// Update team members
// export async function updateTeamMembers(org, teamName, newMember) {
//     const orgRef = doc(db, "organizations", org);
//     const doc = await getDoc(orgRef);
//     const data = doc.data();
    
//     const members = data.teams[teamName].members;
//     members.push(newMember);
    
//     await updateDoc(orgRef, { [`teams.${teamName}.members`]: members });
//   }
  
  // Update projects
export async function createNewProject(org, team, newProjectName, newProject) {
  const orgRef = doc(db, "organizations", org);
  const teamName = team.toLowerCase();
  await updateDoc(orgRef, { [`teams.${teamName}.projects.${newProjectName}`]: newProject }, { merge: true });
}
  
  // Creating a new team
  export async function createNewTeam(org, newTeam, newMembers) {
    const orgRef = doc(db, "organizations", org);
    const teamName = newTeam.toLowerCase();
    const newTeamData = {
      members: newMembers,
      teamLeads: [],
      projects: {},
      password: "",
    };
    await updateDoc(orgRef, { [`teams.${teamName}`]: newTeamData });
  }
  
  // Update authority
//   async function updateAuthority(org, newAuthority) {
//     const orgRef = doc(db, "organizations", org);
//     const doc = await getDoc(orgRef);
//     const data = doc.data();
    
//     const authority = data.authority;
//     authority.push(newAuthority);
    
//     await updateDoc(orgRef, { authority });
//   }