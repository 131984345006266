import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Teams.css";
import TeamBackground from "./images/teams-background.png"
import "react-datepicker/dist/react-datepicker.css";
import { getOrganizationList, getTeams } from '../../functions/FirebaseData';
import Login from '../../functions/Login';



export default function TeamAccessPassword() {
    const navigate = useNavigate()
    const [passwordCheck, setPasswordCheck] = useState("")
    const [teamSelection, setTeamSelection] = useState(false)
    const [selectedTeam, setSelectedTeam] = useState("")
    const [selectedOrganization, setSelectedOrganization] = useState("")


    const [teams, setTeams] = useState([
        <option key={"Select"} value="Select team">Select team</option>
    ])
    const [client, setClient] = useState([])
    const [orgs, setOrgs] = useState([
        <option key={"Select"} value="Select organization">Select organization</option>
    ])

    async function updateSelectedTeam(e) {
        if (e.target.value) {setSelectedTeam(e.target.value)}    
    }
     
    async function fetchOrganizationNames() {
        const organizationsList = await getOrganizationList();
        organizationsList.forEach((name) => {
          setOrgs((orgs) => [...orgs, <option key={name} value={name}>{name}</option>]);
        });
      }

    async function updateFormState(e) {
        if (e.target.value) {
            setClient(e.target.value)
            setSelectedOrganization(e.target.value)
            const teamsList = await getTeams(e.target.value)
                if (teamsList) {
                    teamsList.forEach((name) => {
                        setTeams((teams) => [...teams, <option key={name} value={name}>{name}</option>]);
                        setTeamSelection(true);
                      });
                    }
            }    
    }


    useEffect(() => {
        fetchOrganizationNames()
    }, []) 

    useEffect(() => {
        console.log("Presenting teams")
    }, [teams]) 
    // Listening for password to be correctly entered
    async function login() {
        var login = await Login(passwordCheck, selectedTeam, selectedOrganization)
        if (login === true) {
            return navigate("/teams")
        } else {
            console.log("Wrong password...")
        }
    }

    return (
        <>
        <div className="team-access-container" style={{ backgroundSize: "cover", backgroundImage: `url(${TeamBackground})`}}>
            <div className="team-access-form-container">

                <div className="team-access-password-form">
                    <div className="team-access-welcome-message">
                        Log in
                    </div>
                    <select onChange={(e) => updateFormState(e)} className="team-access-organization-input">
                        {orgs}
                    </select>  
                    {teamSelection &&
                     selectedOrganization != "Select organization" && 
                        <select onChange={(e) => updateSelectedTeam(e)} className="team-access-organization-input" >
                            {teams}
                        </select>
                    }  
                    {(selectedTeam != "" && 
                      selectedTeam != "Select team" &&
                      selectedOrganization != "" &&
                      selectedOrganization != "Select organization"
                      ) &&
                        <>
                        <input type="password" placeholder="Password" className="team-access-password-input" onChange={(e) => setPasswordCheck(e.target.value)}></input>
                        <input title="Submit" onClick={login} className="team-access-form-submit" type="submit"></input>
                        </>
                    }               
                    
                </div>

            </div>



        </div>
        </>
    )    

}
