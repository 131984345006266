import React, { useEffect, useState, Suspense, useRef, useId } from 'react';
import { useNavigate, Link, useLocation, Navigate } from 'react-router-dom';
import "./Dashboard.css";
import DashboardBackground from "./images/dashboard-login-background.png"
import "react-datepicker/dist/react-datepicker.css";
import { doc } from "firebase/firestore";
import {
    db,
    getOrganizationList
} from '../../functions/FirebaseData';
import Login from '../../functions/Login';



const CLIENT_NAME = "Center For Digital Humanities"
const CLIENT_UPDATE = CLIENT_NAME + "_Update"
const TEAM_NAME = "Web Viewer"


const docRef = doc(db,  CLIENT_NAME, "Web Viewer");


export default function DashboardAccessPassword() {

    const [passwordCheck, setPasswordCheck] = useState("")
    const [selectedTeam, setSelectedTeam] = useState("")
    const [selectedOrganization, setSelectedOrganization] = useState("")
    const [orgs, setOrgs] = useState([
        <option key={"Select"} value="Select organization">Select organization</option>
    ])    
    const navigate = useNavigate();
    // Listening for password to be correctly entered
    async function login() {
        var login = await Login(passwordCheck, selectedTeam, selectedOrganization)
        if (login === true) {
            return navigate("/dashboard")
        } else {
            console.log("Wrong password...")
        }
    }

    async function fetchOrganizationNames() {
        const organizationsList = await getOrganizationList();
        organizationsList.forEach((name) => {
            setOrgs((orgs) => [...orgs, <option key={name} value={name}>{name}</option>]);
        });
        }

    useEffect(() => {
        fetchOrganizationNames()
    }, []) 

    async function updateFormState(e) {
        if (e.target.value) {
            setSelectedOrganization(e.target.value)
            }    
    }

    return (
        <>
        <div className="dashboard-access-container-password" style={{ backgroundSize: "cover", backgroundImage: `url(${DashboardBackground})`}}>
            <div className="dashboard-access-form-container">
                <div className="dashboard-access-welcome-message">
                    Log in
                </div>
                <div className="dashboard-access-password-form">
                    <select onChange={(e) => updateFormState(e)} className="dashboard-access-organization-input">
                        {orgs}
                    </select>  
                    <input type="password" placeholder="Password" className="dashboard-access-password-input" onChange={(e) => setPasswordCheck(e.target.value)}></input>
                    <input title="Submit" onClick={login} className="dashboard-access-form-submit" type="submit"></input>
                </div>

            </div>



        </div>
        </>
    )    

}
