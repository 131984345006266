import greeting from '../constants/GreetingsList'
import HashPassword from './HashPassword'
import { doc, getDocs, getDoc, getFirestore, addDoc, collection, query, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from './FirebaseData';


export var TEAM_NAME = greeting
export var CLIENT_NAME = ""
export var LOGIN_STATUS = false

// This function takes the entered password and compares it to the one stored.
    // FETCH HASHED PASSWORD
    export default async function Login(pass, team, org) {
      const teamName = team.toLowerCase();
      const hashedPass = HashPassword(pass);
      const orgRef = doc(db, "organizations", org);
      const docRef = await getDoc(orgRef);
      const data = docRef.data();
      const password = data.teams[teamName].password;
        if (password === hashedPass) {
          CLIENT_NAME = org;
          TEAM_NAME = team;
          LOGIN_STATUS = true
          return true;
        }
        return false;
      } 
    

