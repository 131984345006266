import React, { useState } from 'react';
import "./Admin.css";
import AdminBackground from "./images/admin-background.png"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDoc, collection, updateDoc } from "firebase/firestore";
import {TEAM_NAME, CLIENT_NAME} from './AdminLogin';
import { db } from '../../functions/FirebaseData';
import { Link } from 'react-router-dom';

function AdminAccess({ navigate, ...props }) {
    const [info, setInfo] = useState(null)
    const [startDate, setStartDate] = useState(new Date());
    const [adminName, setAdminName] = useState(null)
    const [memberName, setMemberName] = useState(null)
    const [projectName, setProjectName] = useState(null)
    const [description, setDescription] = useState(null)


  

        
        return (
            <>
            <div className="admin-access-container" style={{ backgroundSize: "cover", backgroundImage: `url(${AdminBackground})`}}>
                <div className="admin-access-form-container">
                    <div className="admin-access-welcome-message">
                        Welcome, {TEAM_NAME}!<div className="admin-update-name">Change</div>
                    </div>
                    <div className="admin-access-update-form">
                        {/* Button to add a new team member */}
                        <Link to={"/admin-updates"}
                            state={{ client: CLIENT_NAME, action: "add-remove" }}
                            className="admin-add-remove-button">
                            Members
                        </Link>
                        <Link to={"/admin-update-projects"}
                            state={{ client: CLIENT_NAME, team: TEAM_NAME }}
                            className="admin-add-remove-button">
                            Projects
                        </Link>
                        <Link to={"/admin-update-teams"}
                            state={{ client: CLIENT_NAME }}
                            className="admin-add-remove-button">
                            Teams
                        </Link>
                        <Link to={"/admin-updates"}
                            state={{ client: CLIENT_NAME, action: "add-remove" }}
                            className="admin-add-remove-button">
                            Billing
                        </Link>
                        <Link to="/admin-updates" className="admin-add-remove-button admin-transfer-ownership">
                            Transfer ownership
                        </Link>
                        <Link to="/admin-updates" className="admin-add-remove-button admin-transfer-ownership">
                            Delete organization
                        </Link>
                    </div>

                </div>



            </div>
            </>
        )
    



}
export default AdminAccess;
