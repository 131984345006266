const colors = [
    "#438695", 
    "#91433F", 
    "#668744", 
    "#CF6E30", 
    "#BD547E", 
    "#755292", 
    "#4E817E", 
    "#764156", 
    "#4B5975"
]

export {colors};
