import React, { useEffect, useState } from 'react';
import "./AdminUpdates.css";
import AdminBackground from "../../screens/admins/images/admin-background.png"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDoc, collection } from "firebase/firestore";
import {TEAM_NAME, CLIENT_NAME} from '../../screens/admins/AdminLogin';
import { db, getTeams, getMembersNames } from '../../functions/FirebaseData';
// import { getTeams } from '../../functions/GetTeams';
import { useLocation } from 'react-router-dom';
import { createNewTeam } from './AdminFunctions';

export default function AdminUpdateTeams({ navigate, ...props }) {
    const location = useLocation();
    const org = location.state.client
    const [members, setMembers] = useState([])
    const [newTeam, setNewTeam] = useState("")
    const [numMembers, setNumMembers] = useState(1);
    const [newMembers, setNewMembers] = useState([]);


    async function addNewTeam() {
      console.log("Adding new team")
      await createNewTeam(org, newTeam, newMembers)
    }

    const handleNameChange = (index, value) => {
      const members = [...newMembers];
      members[index].name = value;
      setNewMembers(members);
    };
  
    const handleEmailChange = (index, value) => {
      const members = [...newMembers];
      members[index].email = value;
      setNewMembers(members);
    };

    const addNewMember = () => {
      setNewMembers([...newMembers, { name: "", email: "" }]);
      setNumMembers(numMembers + 1);
    };


    useEffect(() => {
      const getMembers = async () => {
        var membersList = new Set()
        const teams = await getTeams(org)
        for (const team of teams) {
          const members = await getMembersNames(org, team)
          if (members) {
            for (const member of members) {
              membersList.add(member)
            }
          }
        }
        setMembers([...membersList]); // Convert Set back to an array
      }
    if (org) {
        getMembers()
    }
}, [])

    return (
      <>
        <div className="admin-updates-access-container">
          <div className="admin-updates-access-form-container">
            <div className="admin-updates-access-welcome-message">
              Welcome, {org}!
            </div>
            <div className="admin-updates-access-update-form">
              {members && members.map((member) => {
                return (
                  <>
                    <div className="admin-updates-add-remove-button">
                      <div>+</div>
                    </div>
                    <div className="admin-updates-add-remove-member">
                    <div className="admin-updates-member-name">
                      {member}
                    </div>
                    <div>Edit</div>
                    <div>Archive</div>
                  </div>
                  <div>
                </div>
                
                </>
                )})}
                <br/>
                <div>
      <input
        className="input-input"
        placeholder="New team"
        value={newTeam}
        onChange={(e) => setNewTeam(e.target.value)}
      ></input>
      {[...Array(numMembers)].map((_, index) => (
        <div key={index}>
          <input
            className="input-input"
            placeholder={`New member name ${index + 1}`}
            value={newMembers[index]?.name || ""}
            onChange={(e) => handleNameChange(index, e.target.value)}
          ></input>
          <input
            className="input-input"
            placeholder={`New member email ${index + 1}`}
            value={newMembers[index]?.email || ""}
            onChange={(e) => handleEmailChange(index, e.target.value)}
          ></input>
        </div>
      ))}
      <button onClick={addNewMember}>Add new member</button>
      <button onClick={addNewTeam} type="submit" className="button-button">
        Submit
      </button>
    </div>
              </div>
            </div>
        </div>
      </>
    )
  }